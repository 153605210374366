import * as crypto from 'crypto-js';
import { nanoid } from 'nanoid';
const codeVerifier = nanoid(128);

export const login = (provider = '') => {
  const challenge = base64URLEncode(crypto.SHA256(codeVerifier));
  const state = nanoid(40);

  localStorage.setItem('state', state);
  localStorage.setItem('verifier', codeVerifier);

  const url = `${import.meta.env.VITE_OAUTH_URL}/oauth/authorize?client_id=5&redirect_uri=${window.location.origin}/&response_type=code&scope=*&state=${state}&code_challenge=${challenge}&code_challenge_method=S256`;
  // const url = `https://myhutest.b2clogin.com/myhutest.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN&client_id=93e24f68-236b-4fc2-a6b7-5b436059ff6a&redirect_uri=${window.location.origin}/&response_type=id_token&scope=openid&state=${state}&code_challenge=${challenge}&code_challenge_method=S256`;

  // window.open(url, 'popup', 'width=700,height=700')

  if (provider) {
    window.location.href = `${provider}?callback=${window.btoa(url)}`;
  } else {
    window.location.href = url;
  }

  localStorage.setItem('redirect_after_login', `${window.location.href}`);
};

export const signUp = () => {
  const challenge = base64URLEncode(crypto.SHA256(codeVerifier));
  const state = nanoid(40);

  localStorage.setItem('state', state);
  localStorage.setItem('verifier', codeVerifier);

  const url = `${import.meta.env.VITE_OAUTH_URL}/oauth/authorize?client_id=5&redirect_uri=${window.location.origin}/&response_type=code&scope=openid&state=${state}&response_type=id_token&prompt=login`;

  window.location.href = `${import.meta.env.VITE_OAUTH_URL}/register?callback=${window.btoa(url)}`;
};

const base64URLEncode = (string: crypto.lib.WordArray | string): string => {
  return string
    .toString(crypto.enc.Base64)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};
